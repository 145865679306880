<template>
	<div class="mine">
		<!-- 头像卡片 -->
		<div class="mine-card">
			<van-row>
				<van-col span="6">
					<van-image fit="cover" round width="4.2rem" height="4.2rem" :src="user.avatar?imageUrl+user.avatar:userHead" @click="goUpdateUser"/>
				</van-col>
				<van-col span="15">
					<div v-if="JSON.stringify(user) == '{}'" class="mine-card-title"
						@click="toUrl('/login',{ redirect })">未登录</div>
					<div v-else class="mine-card-title">
						<span v-if="user.nickname">{{ user.nickname }}</span>
					<span>{{ user.tel }}</span>
					</div>
				</van-col>
				<div class="mine-icon">
					<div class="mine-icon-item" @click="collect">
						<img src="../../assets/shoucang.png" alt="">
						<span>收藏</span>
					</div>
					<div class="mine-icon-item" @click="history">
						<img src="../../assets/zuji.png" alt="">
						<span>足迹</span>
					</div>
					<div class="mine-icon-item" @click="coupon">
						<img src="../../assets/CardCoupon.png" alt="">
						<span>卡券</span>
					</div>
					
					<div class="mine-icon-item" @click="concern">
						<img src="../../assets/guanzhu.png" alt="">
						<span>关注</span>
					</div>
				</div>
				<!-- <van-col span="3">
					<van-icon name="arrow" class="mine-card-setting" @click="toUrl('/setting')" />
				</van-col> -->
			</van-row>
		</div>
		<div class="mine-order">
			<div v-for="(item,index) in order" class="mine-order-item" @click="goMyOrders(index)">
				<img :src="item.url" alt="">
				<span>{{item.name}}</span>
			</div>
		</div>
		<!-- 操作栏 -->
		<div class="mine-setting">
			<van-cell-group>
				<van-cell icon="location-o" title="收货地址" @click="address" is-link />
				<van-cell icon="service-o" title="联系客服"  is-link />
				<van-cell icon="phone-o" title="关于我们" is-link />
				
				<!-- <van-cell icon="share-o" title="退出登录" @click="quitLogin" is-link /> -->
			</van-cell-group>
		</div>
		
		<van-popup v-model:show="pass" style="border-radius: 10px;">
			<van-form @submit="onSubmit" class="pass-form">
				<van-field label="原密码" label-width="5rem" size="large" clearable v-model="old_password" name="validator"
					placeholder="请输入密码" :rules="[{ required:true, message: '请输入密码' }]" />
				<van-field label="新密码" size="large" label-width="5rem" clearable v-model="new_password" name="pattern"
					placeholder="请输入账号" :rules="[{ required:true, message: '请输入账号' }]" />
				<div style="margin-top:2rem;">
					<van-button round block type="primary" native-type="submit">提交修改</van-button>
				</div>
			</van-form>
		</van-popup>
	</div>
</template>
<script>
	import {
		info,
		modifyPassword
	} from '@/api/user'
	import {
		toUrl
	} from '@/utils/tools'
	import {
		Toast,
		Dialog
	} from "vant";

	export default {
		data() {
			return {
				imageUrl:this.url.imageUrl,
				pass: false,
				user: {},
				redirect: window.location.href,
				old_password: '',
				new_password: '',
				order: [{
						name: '全部订单',
						url: require('@/assets/dingdan.png')
					},
					{
						name: '待付款',
						url: require('@/assets/daifukuan.png')
					},
					{
						name: '待发货',
						url: require('@/assets/daifahuo.png')
					}, {
						name: '待收货',
						url: require('@/assets/daishouhuo.png')
					}, {
						name: '待评价',
						url: require('@/assets/daipingjia.png')
					},
				],
				userHead:require('@/assets/userHead.png')
			}
		},
		mounted() {
			// console.log(this.$store.state)
			this.load()
		},
		methods: {
			//修改用户信息
			goUpdateUser(){
				this.$router.push({
					path:'/updateUser'
				})
				},
			//我的订单
			goMyOrders(type){
				console.log(typeof(type))
				this.$router.push({
					path:'/myOrders',
					query:{
						type:type
					}
				})
				},
			load() {
				info().then((res) => {
					this.user = res.data.info
				})
			},
			toUrl(path, param = null) {
				toUrl(path, param)
			},
			modifyPwd() {
				this.pass = !this.pass
			},
			coupon(){
				this.$router.push({
					path:'/userCoupon'
				})
				
				},
			address(){
				this.$router.push({
					path:'/addressUser'
				})
			},
			concern(){
				this.$router.push({
					path:'/concern'
				})
			},
			onSubmit() {
				if (this.old_password === this.new_password) Toast.fail('新旧密码不能一样');
				modifyPassword({
					'new_password': this.new_password,
					'old_password': this.old_password
				}).then((res) => {
					Toast({
						message: res.msg,
					});
					this.$store.commit('SET_TOKEN', "")
					this.$store.commit('SET_IS_LOGIN', false)
					this.$router.push('/login')
				})
			},
			quitLogin() {
				Dialog.alert({
					closeOnClickOverlay: true,
					message: '退出登录',
				}).then(() => {
					window.localStorage.clear()
					this.$store.commit('SET_TOKEN', "")
					this.$store.commit('SET_IS_LOGIN', false)
					this.$router.push('/login')
				});
			},
			collect(){
				this.$router.push({
					path:'/collect'
				})
			},
			history(){
				this.$router.push({
					path:'/history'
				})
			}
		}
	}
</script>
<style lang="less">
	.mine {
		min-height: calc(100vh);

		.mine-order {
			display: flex;
			align-items: center;
			
			background-color: #FFFFFF;
			padding: 20px;
			width: 85%;
			margin: 0 auto;
			border-radius: 10px;
			position: relative;
			top: -30px;

			.mine-order-item {
				width: 20%;
				display: flex;
				flex-direction: column;
				align-items: center;

				img {
					width: 30px;
					height: 30px;
				}

				span {
					font-size: 12px;
					margin-top: 5px;
				}
			}

		}

		.mine-card {
			background: #ffa000;
			padding: 30px 15px;
			height: 150px;

			.mine-icon {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				padding: 20px;

				.mine-icon-item {
					display: flex;
					flex-direction: column;
					align-items: center;

					img {
						width: 30px;
						height: 30px;
					}

					span {
						font-size: 12px;
					}
				}
			}
		}

		.mine-setting {
			margin: 10px 10px;
			border-radius: 10px;
			overflow: hidden;
			position: relative;
			top: -30px;
		}

		.mine-card-title {
			// line-height: 70px;
			height: 100%;
			display: flex;
			justify-content: space-around;
			flex-direction: column;
		}

		.mine-card-setting {
			line-height: 70px;
			float: right;
			font-size: 18px;
		}

		.pass-form {
			width: 18rem;
			padding: 2rem 1rem;
		}
	}
</style>
